
import { Vue, Component, Watch } from "vue-property-decorator";
import { AlmoxarifadoService, GrupoProdutoService, ProdutoService, TipoProdutoService } from "@/core/services/almoxarifado";
import { AlmoxarifadoItem, Produto } from "@/core/models/almoxarifado";
import { mixins } from "vue-class-component";
import ListPage from "@/components/shared/ListPage";

@Component
export default class ListaProduto extends mixins(Vue, ListPage) {
 
  selected = new Produto();
  item = new Produto(); 
  service = new ProdutoService();
  almoxarifados = []; 

  dialogSaldo: boolean = false;
  loading: boolean = false;
  dialogCadastro: boolean = false;
  totalLista: number = 0;
 
  titulo: string = "Produtos";
  subTitulo: string = "Lista de produtos cadastrados no Sistema";

  options: any = {
    itemsPerPage: 15,
  };

  
  breadCrumbLinks: any[] = [
    { text: 'Home', href: '/financeiro/painel/kpi',},
    { text: 'Lista de Produtos', disabled: true, href: '/financeiro/painel/despesas'}
  ]

  headers: any[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Id", value: 'codigo', type:'number'},
    { text: "Nome", value: 'nome'},
    { text: "Tipo", value: 'tipo.nome'},
    { text: "Grupo", value: 'grupo.nome'},
    { text: "Saldo", value: 'saldo', type:'text', sortable: false},
    { text: "Custo Médio", value: 'precoCustoMedio', type:'text'},
    { text: "Ativo", value: "ativo", type:'text' } 
  ];

  getColor(item: AlmoxarifadoItem, selected: Produto) {
    if (item.quantidade <= selected.estoqueCritico) return "red";
    else if (item.quantidade <= selected.estoqueMinimo) return "orange";
    else return "green";
  }

  @Watch("options", { deep: true })
  Atualizar() {
    const { page, itemsPerPage, sortBy, sortDesc, columns } = this.options;
    this.loading = true;
      //page, itemsPerPage, sortBy, sortDesc, this.search, this.headers, columns, 'AlmoxarifadoItens, Grupo, Tipo',

    this.service.Listar(page,itemsPerPage,sortBy,sortDesc,this.search, columns, undefined, '', '', 'AlmoxarifadoItens, Grupo, Tipo').then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
      (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          }
        })
    .finally(() => (this.loading = false));
  }
  
  Novo() {
    this.item = new Produto();
    this.dialogCadastro = true;
  }

  Excluir(item: Produto) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.Excluir(item.id).then(
          (res) => {
            if (res.status == 200) {
              return res;
            }
          },
          (err) => this.$swal("Aviso", err.response.data, "error")
        );
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result:any) => {
      if (result.value) {
        this.$swal(
          "Aviso",
          result.value.data,
          result.value.status == 200 ? "success" : "warning"
        );
        this.Atualizar();
      }
    });
  } 

  AbrirSaldo(item: Produto) {
    this.selected = item;
    this.dialogSaldo = true;
  } 

  mounted() { 
    //this.Atualizar();

    let almoxarifadoService = new AlmoxarifadoService();
    almoxarifadoService.ListarTudo().then(
      res => {
        this.almoxarifados = res.data.items
      }
    )

  }
}
